export const METRICS_PARAMETERS = [
  'profit',
  'maxSize',
  'mae',
  'mfe',
  'timeInTrade',
  'riskRewardRatio',
] as const
export type MetricsParameter = (typeof METRICS_PARAMETERS)[number]
export const METRICS_AGGREGATIONS = [
  'average',
  'total',
  'max',
  'min',
  'deviation',
  'relative_deviation',
] as const
export type MetricsAggregation = (typeof METRICS_AGGREGATIONS)[number]
export const METRICS_CLASSIFICATIONS = [
  'instrumentAlias',
  'journal',
  'winLoss',
  'longShort',
  'dayOfWeek',
  'timeOfDay',
  'profit',
  'maxSize',
  'mae',
  'mfe',
  'timeInTrade',
  'riskRewardRatio',
] as const
export type MetricsClassification = (typeof METRICS_CLASSIFICATIONS)[number]

export type MetricsCellConfig = {
  parameter: MetricsParameter
  aggregation: MetricsAggregation
  classification: MetricsClassification
}
