import './MetricsPage.scss'

import { FilterContext } from 'pages/DashboardPage/FilterContext'
import { useContext, useMemo, useState } from 'react'
import Card from 'shared/Card'
import { SimpleDropdown } from 'shared/Dropdown'
import PageLayout from 'shared/PageLayout'
import {
  METRICS_AGGREGATIONS,
  METRICS_CLASSIFICATIONS,
  METRICS_PARAMETERS,
  MetricsCellConfig,
} from './models'
import { Controller, useForm } from 'react-hook-form'
import { API_URL } from 'core/constants'
import useSWR from 'swr'
import { apiClientFetcher } from 'core/api/apiClientFetcher'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Rectangle,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts'

type MetricsCellForm = MetricsCellConfig

function useMetricsValues(config: MetricsCellConfig) {
  const { filters } = useContext(FilterContext)
  const url = useMemo(() => {
    const url = new URL(API_URL + '/api/dashboard/widgets/barDiagram')
    filters.journal && url.searchParams.append('journalIds', filters.journal)
    filters.symbols.length &&
      url.searchParams.append('instrumentAliases', filters.symbols.join(','))
    url.searchParams.append('fromTime', new Date(Date.now() - 10e9).toISOString().split('Z')[0])
    url.searchParams.append('toTime', new Date(Date.now() + 10e10).toISOString().split('Z')[0])
    url.searchParams.append('parameter', config.parameter)
    url.searchParams.append('aggregation', config.aggregation)
    url.searchParams.append('classification', config.classification)
    return url
  }, [filters, config])

  return useSWR<{ result: { classifier: string; value: number }[] }>(
    url.toString(),
    apiClientFetcher
  )
}

function MetricsCell() {
  const [config, setConfig] = useState<MetricsCellConfig>({
    parameter: 'profit',
    aggregation: 'average',
    classification: 'instrumentAlias',
  })
  const { control, watch } = useForm<MetricsCellForm>({ defaultValues: config })
  watch((v) => {
    if (!v.aggregation || !v.classification || !v.parameter) return
    setConfig(v as MetricsCellConfig)
  })
  const { data, error } = useMetricsValues(config)

  const size = Math.max((data?.result.length ?? 1) * 24, 500)

  return (
    <Card className="MetricsCell">
      <div className="header">
        <div className="name">Widget Name</div>
        <form className="params">
          <Controller
            control={control}
            name="parameter"
            render={({ field }) => (
              <SimpleDropdown onChange={field.onChange} onBlur={field.onBlur} value={field.value}>
                {METRICS_PARAMETERS.map((v) => (
                  <SimpleDropdown.Item key={v} value={v}>
                    {v}
                  </SimpleDropdown.Item>
                ))}
              </SimpleDropdown>
            )}
          />

          <Controller
            control={control}
            name="aggregation"
            render={({ field }) => (
              <SimpleDropdown onChange={field.onChange} onBlur={field.onBlur} value={field.value}>
                {METRICS_AGGREGATIONS.map((v) => (
                  <SimpleDropdown.Item key={v} value={v}>
                    {v}
                  </SimpleDropdown.Item>
                ))}
              </SimpleDropdown>
            )}
          />

          <Controller
            control={control}
            name="classification"
            render={({ field }) => (
              <SimpleDropdown onChange={field.onChange} onBlur={field.onBlur} value={field.value}>
                {METRICS_CLASSIFICATIONS.map((v) => (
                  <SimpleDropdown.Item key={v} value={v}>
                    {v}
                  </SimpleDropdown.Item>
                ))}
              </SimpleDropdown>
            )}
          />
        </form>
      </div>
      <div className="chart-section">
        <div className="chart">
          {data && (
            <ResponsiveContainer width="100%" height={size}>
              <BarChart
                data={data.result}
                layout="vertical"
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <Bar
                  dataKey="value"
                  fill="#8884d8"
                  activeBar={<Rectangle fill="pink" stroke="blue" />}
                />
                <Tooltip />
                <YAxis dataKey="classifier" type="category" width={100} />
                <XAxis dataKey="value" type="number" scale="sqrt" />
                <ReferenceLine x={0} stroke="#f00" />
                <CartesianGrid strokeDasharray="1" stroke="#444" />
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>

        {data && (
          <div className="value-grid">
            {data.result.map((v, index) => (
              <div key={index + 'a'}>
                <div>{v.classifier}</div>
                <div>{v.value}</div>
              </div>
            ))}
          </div>
        )}

        {error && <div className="error">Error: {error.toString()}</div>}
      </div>
    </Card>
  )
}

export function MetricsPage() {
  return (
    <PageLayout className="MetricsPage">
      <PageLayout.Header />
      <PageLayout.Content>
        <h2>Metrics</h2>
        <div className="grid">
          <MetricsCell />
          {/* <MetricsCell /> */}
        </div>
      </PageLayout.Content>
      <PageLayout.Footer />
    </PageLayout>
  )
}
